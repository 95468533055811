<template>
  <v-main>
    <v-container fruid class="bl_works">
        <h3 class="my-8">大室城イメージイラストができるまで</h3>
        <video playsinline controls muted src="/img/direct_img/mv_oomurijo_minimini_short.mp4" class="bl_movie"></video><br />
        <span>2022年3月公開</span><br />
        <span>制作環境：Adobe Fresco（一部Adobe Illustrator）</span>
    </v-container>

  </v-main>
</template>

<script>

  export default {
    name: 'works-oomurojo',
    components: {

    },
    data(){
      return{
      }
    },
  }
</script>

<style scoped>
.bl_works >>> img{
  width: 300px;
  max-width: 300px;
  height: 300px;
  max-height: 300px;
  object-fit: cover;
}
</style>
